import React, {Fragment, useContext, useEffect} from 'react';
import DataTable from './DataTable';
import AdminDashboardCards from './Cards';

const UserDetails =({allUserDetails, updateUserDetails, deleteUser, setPage,
    setPageSize, setSearch, search}) => {
  

    return(
        <Fragment>
                <DataTable allUserDetails={allUserDetails} updateUserDetails={updateUserDetails} deleteUser={deleteUser}
                setPage={setPage}
                setPageSize={setPageSize}
                setSearch={setSearch}
                search={search}/>
        </Fragment>
    )
};
export default UserDetails;


