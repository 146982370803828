import React, { useContext, useState } from "react";
import Slider from "react-slick";
import { Link } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import hero1 from '../../images/slider/1.png'
import hero2 from '../../images/slider/5.png'
import UserContext from "../ContextApi/UserContext";
import { clearLocalData } from "../../utils/CoustomStorage";
import LoginModal from "../common/LoginModal";

const settings = {
    dots: false,
    arrows: true,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2500,
    fade: true
};

const Hero = () => {
    const { user } = useContext(UserContext)
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const logout = () => {
        clearLocalData()
        window.location.reload();
    }
    return (
        <section className="hero hero-slider-wrapper hero-style-1">
            <div className="hero-slider">
                <Slider {...settings}>
                    <div className="slide">
                        <div className="container">
                            <div className="row">
                                <div className="col col-lg-6 col-md-8 col-sm-12 slide-caption">
                                    <div className="slide-title-sub">
                                        <h5>Trusted Business Partner.</h5>
                                    </div>
                                    <div className="slide-title">
                                        <h2>Your Gateway to Intelligent Credit Card Solutions</h2>
                                    </div>
                                    <div className="slide-subtitle">
                                        <p>Welcome to SMARTSCOIN: Where Smart Choices Meet Limitless Rewards with Our Range of Credit Cards!</p>
                                    </div>
                                    <div className="btns">
                                        {user?.role=='user' ? 
                                        <>
                                            <Link onClick={logout} className="theme-btn mr-4">Logout</Link>
                                            <Link className="theme-btn" style={{marginLeft: '10px'}} to="/apply">Go To Application</Link>
                                        </>
                                                : 
                                            <Link onClick={toggle} className="theme-btn mr-4">Login</Link>
                                    }
                                    </div>
                                    <div className="slider-pic">
                                        <img src={hero1} alt="" className="image_slider_pic"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slide">
                        <div className="container">
                            <div className="row">
                                <div className="col col-lg-6 col-md-8 col-sm-12 slide-caption">
                                    <div className="slide-title-sub">
                                        <h5>Trusted Business Partner.</h5>
                                    </div>
                                    <div className="slide-title">
                                        <h2>Provide Financial Solution Service.</h2>
                                    </div>
                                    <div className="slide-subtitle">
                                        <p>It was popularised in the 1960s with the release sheets. We bring the right
                                            people together</p>
                                    </div>
                                    <div className="btns">
                                        <Link to="/about" className="theme-btn" style={{marginRight: '10px'}}>Explore More</Link>
                                    {user?.role=='user' ? 
                                            <Link onClick={logout} className="theme-btn">Logout</Link>
                                                : 
                                            <Link onClick={toggle} className="theme-btn">Login</Link>
                                    }
                                    </div>
                                    <div className="slider-pic">
                                        <img src={hero2} alt=""  className="image_slider_pic" style={{borderRadius:'50%'}} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
            <LoginModal modal={modal} toggle={toggle} />

        </section>
    )
}

export default Hero;